.infoCard {
  background-color: lightyellow;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding:1rem;
}

h3 {
    margin-bottom:1rem;
}

h4 {
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* You can add global styles to this file, and also import other style files */

/*THEME STYLING*/
/*TrackTime*/
.mw_main_table_header {
  margin-left: 0;
  margin-bottom: 0.25rem;
  height: 3rem;
  background-color: rgb(13,130,197)
}
.mw_sub_table_header {
  margin:0;
  height: 3rem;
  background-color: lightgray;
}


.mw_img {

}
  .mw_img:hover {
    cursor: pointer;
  }

/*STYLING BUTTONS*/

.mw-editBtn-icon {
  color: cornflowerblue;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  padding: 0.3rem
}
  .mw-editBtn-icon:hover {
    cursor: pointer;
    background-color: azure;
  }
.mw-deleteBtn-icon {
  color: indianred;
  background-color: transparent;
  border-radius: 50%;
  padding: 0.3rem;
  border: none;
  margin-right: 1rem;
  height: auto;
  width: auto;
}
  .mw-deleteBtn-icon:hover {
    cursor: pointer;
    background-color: azure;
  }
.mw-deleteIcon-icon {
  font-weight: bolder;
}

.mw-addBtn-icon {
  color: darkseagreen;
  background-color: transparent;
  border-radius: 50%;
  padding: 0.3rem;
  border: none;
  margin-right: 1rem;
  height: 3rem;
  width: 3rem;
}
  .mw-addBtn-icon:hover {
    cursor: pointer;
    background-color: lightyellow;
  }
.mw-addIcon-icon {
  font-size: 2.5rem;
  font-weight: bolder;
}

.mw-pauseBtn-icon {
  color: burlywood;
  background-color: transparent;
  border-radius: 50%;
  padding: 0.3rem;
  border: none;
  margin-right: 1rem;
  height: 3rem;
  width: 3rem;
}
  .mw-pauseBtn-icon:hover {
    cursor: pointer;
    background-color: lightyellow;
  }
.mw-pauseIcon-icon {
  font-size: 2.5rem;
  font-weight: bolder;
}

.mw-blackBtn-icon {
  color: black;
  background-color: transparent;
  border-radius: 50%;
  padding: 0.3rem;
  border: none;
  margin: 0.5rem;
  height: 3rem;
  width: 3rem;
  font-size: 2.5rem;
  font-weight: bolder;
}
  .mw-blackBtn-icon:hover {
    cursor: pointer;
    background-color: lightyellow;
  }

.mw-okBtn-icon {
  color: darkseagreen;
  background-color: transparent;
  border-radius: 50%;
  padding: 0.3rem;
  border: none;
  margin-right: 1rem;
  height: auto;
  width: auto;
}
  .mw-okBtn-icon:hover {
    cursor: pointer;
    background-color: azure;
  }
.mw-okIcon-icon {
  font-weight: bolder;
}

.mw-addDBBtn-icon {
  color: darkseagreen;
  background-color: dimgray;
  border-radius: 0.5rem;
  padding: 0.25rem;
  border: none;
  height: 2.5rem;
  width: 15rem;
}
  .mw-addDBBtn-icon:hover {
    cursor: pointer;
    background-color: lightgray;
  }
.mw-addDBIcon-icon {
  font-size: 2rem;
  font-weight: bolder;
}


.toggleBtn {
  background-color: lightblue;
  padding: 0px;
  margin: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  border: none;
  border-radius: 50%
}
.toggleBtn:active {
  outline: none;
  border: none;
}
.toggleBtn:focus {
    outline:0
}


.mw-editBtn-icon_sm {
  background-color: transparent;
  padding: 0px;
  margin: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  border-radius: 50%;
  font-size: 0.5rem;
  color: cornflowerblue;
}
.mw-editBtn-icon_sm:hover {
    cursor: pointer;
    background-color: lightcyan;
}
.mw-editBtn-icon_sm:active {
    outline: none;
    border: none;
  }
.mw-editBtn-icon_sm:focus {
    outline: 0
}


.mw-doneBtn-icon_sm {
  background-color: transparent;
  padding: 0px;
  margin: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  border-radius: 50%;
  font-size: 0.5rem;
  color: darkseagreen;
}
  .mw-doneBtn-icon_sm:hover {
    cursor: pointer;
    background-color: lightcyan;
  }
  .mw-doneBtn-icon_sm:active {
    outline: none;
    border: none;
  }
  .mw-doneBtn-icon_sm:focus {
    outline: 0
  }


.mw-cancelBtn-icon_sm {
  background-color: transparent;
  padding: 0px;
  margin: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  border-radius: 50%;
  font-size: 0.5rem;
  color: indianred;
}
  .mw-cancelBtn-icon_sm:hover {
    cursor: pointer;
    background-color: lightcyan;
  }
  .mw-cancelBtn-icon_sm:active {
    outline: none;
    border: none;
  }
  .mw-cancelBtn-icon_sm:focus {
    outline: 0
  }

.snackBarErr {
  color: indianred;
  font-weight: bolder;
  background-color:white;      
}
.snackBarOk {
  color: white;
  font-weight: bold;
  background-color: mediumseagreen;
}
.div_mw {
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
}

html, body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
p {
  color: black;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
}


.MatCard-mw {
  background-color: lightyellow;
  margin: 0.5rem;
  border:ridge;
  border-radius:0.5rem;
}

/*STYLING FOR TABLE HEADER*/
.name-header-sm {
  font-size: 24px;
  margin-right: 10px;
  font-weight: bold;
}
/*.mat-dialog-container {
  display: flex;
  justify-content: flex-start;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}*/

/*::ng-deep.custom-dialog-container {
  margin: 0px;
  padding: 0px !important;
  width: 10rem;
  height: 100%;
  border-radius: 50px;
  background-color: red;
}*/

/*::ng-deep.mat-dialog-container {
  padding: 0px !important;
}*/


.mat-dialog-container {
  padding: 0px !important;
  border-radius: 10px !important;
  /*max-height: 95% !important;*/
  overflow-y: auto !important;
}

/*STYLING FOR TABS*/
.tabheader-mw {
  background-color: lightyellow;
}

/*STYLING FOR INPUT DIALOGS*/

.mw-mat-close-button {
  float: right;
  top: 2px;
  right: 2px;
  background-color: transparent;
}
.mw-mat-close-icon {
  transition: 1.5s ease-in-out;
}

@media only screen and (max-width:599px) {
  .custom-dialog-container {
    max-height: 80%;
    width: 100%;
  }
}





/*STYLING TABLES*/

.mw-smallTableheader {
  background-color: gainsboro;
}
.mw-small-tableRow {
  padding: 0rem;
  margin: 0rem;
  height: 2.5rem;
}

/*STYLING BUTTONS*/

.roundLgBtn {
  background-color: darkseagreen;
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

  .roundLgBtn:hover {
    background-color: gainsboro;
  }
